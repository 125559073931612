import {
  PButton,
  PCheckboxWrapper,
  PHeading,
  PInlineNotification,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import {
  ActionGroup,
  DayPickerInput,
  Spacer,
  Spinner,
  styled,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ClientError } from 'graphql-request';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { COMMON_NAMESPACE, NAMESPACES } from '../../../config/i18n';
import { usePersonContext } from '../../../context';
import {
  Announcement as AnnouncementModel,
  AnnouncementsQuery,
  useAnnouncementsQuery,
  useUpdateAnnouncementMutation,
} from '../../../graphql';
import { useNotification } from '../../../hooks';

export const Announcement = () => {
  const { t } = useTranslation(NAMESPACES);
  const { addToast } = useNotification();
  const queryClient = useQueryClient();

  const dateFormat =
    usePersonContext().state.settings?.dateFormat || 'YYYY/MM/DD';

  const {
    data: announcement,
    isLoading,
    fetchStatus,
  } = useAnnouncementsQuery(undefined, {
    select: (data) => data.announcements[0],
  });

  const { mutateAsync: updateAnnouncement, error } =
    useUpdateAnnouncementMutation({
      onSuccess: (result) => {
        queryClient.setQueryData<AnnouncementsQuery>(
          useAnnouncementsQuery.getKey(),
          (cache) => ({
            ...cache,
            announcements: cache?.announcements.length
              ? cache.announcements.map((announcement, index) => ({
                  ...announcement,
                  ...(index === 0 && result.updateAnnouncement),
                }))
              : [result.updateAnnouncement],
          }),
        );
      },
    });

  const {
    watch,
    handleSubmit,
    control,
    formState: { defaultValues, isSubmitting },
    setValue,
    getValues,
  } = useForm({
    values: {
      id: announcement?.id,
      isActive: !!announcement?.isActive,
      message: announcement?.message || '',
      from: announcement?.from || '',
      to: announcement?.to || '',
    },
  });

  const { message } = useAnnouncementMessage();

  if (isLoading && fetchStatus !== 'idle') {
    return <Spinner />;
  }

  return (
    <>
      <PHeading role="heading" aria-level={2} tag="h2" size="large">
        {t('admin.announcement.title')}
      </PHeading>

      <Spacer mb="$medium" />

      {error && (
        <>
          <PInlineNotification
            heading={t('common:error')}
            description={`${
              error instanceof ClientError
                ? error.response.errors?.[0].message ?? error.message
                : `${error}`
            }`}
            state="error"
          />

          <Spacer mb="$medium" />
        </>
      )}

      {announcement?.isActive && (
        <>
          <PInlineNotification
            heading={message(announcement)}
            state="info"
            dismissButton={false}
          />

          <Spacer mb="$large" />
        </>
      )}

      <form
        onSubmit={handleSubmit(async (values) => {
          await updateAnnouncement(
            {
              input: {
                ...values,
                from: values.from || null,
                to: values.to || null,
              },
            },
            {
              onSuccess: () => {
                addToast({
                  text: t('common:saveSuccessful'),
                  state: 'success',
                });
              },
            },
          );
        })}
      >
        <Controller
          control={control}
          name="isActive"
          render={({ field: { value, onChange, ...field } }) => (
            <PCheckboxWrapper label={t('admin.announcement.activate')}>
              <input
                type="checkbox"
                checked={!!value}
                onChange={(event) => {
                  onChange(event);

                  // Message is a mandatory field, therefore use the initial value if the field has been deleted.
                  if (value && !getValues('message')) {
                    setValue('message', defaultValues?.message || '');
                  }
                }}
                {...field}
              />
            </PCheckboxWrapper>
          )}
        />

        <Spacer mb="$medium" />

        {watch('isActive') && (
          <>
            <Controller
              control={control}
              name="message"
              rules={{ required: true }}
              render={({ field }) => (
                <PTextFieldWrapper label={t('admin.announcement.title')}>
                  <input type="text" {...field} required />
                </PTextFieldWrapper>
              )}
            />

            <Spacer mb="$medium" />

            <Container>
              <Controller
                control={control}
                name="from"
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <DayPickerInput
                    {...field}
                    inputRef={ref}
                    format={dateFormat}
                    label={t('admin.announcement.from')}
                    selected={value ? new Date(value) : undefined}
                    onSelect={(date) => {
                      onChange(
                        (date && dayjs(date).format('YYYY-MM-DD')) || '',
                      );
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="to"
                rules={{
                  min: {
                    value: watch('from'),
                    message: t('admin.announcement.dateShouldBeAfterFrom'),
                  },
                }}
                render={({
                  field: { ref, value, onChange, ...field },
                  fieldState,
                }) => (
                  <DayPickerInput
                    {...field}
                    inputRef={ref}
                    format={dateFormat}
                    label={t('admin.announcement.to')}
                    min={watch('from')}
                    state={fieldState.error ? 'error' : 'none'}
                    message={fieldState.error?.message}
                    selected={value ? new Date(value) : undefined}
                    onSelect={(date) => {
                      onChange(
                        (date && dayjs(date).format('YYYY-MM-DD')) || '',
                      );
                    }}
                  />
                )}
              />
            </Container>

            <Spacer mb="$medium" />
          </>
        )}

        <ActionGroup>
          <div />
          <PButton type="submit" variant="primary" loading={isSubmitting}>
            {t('action.save', { ns: COMMON_NAMESPACE })}
          </PButton>
        </ActionGroup>
      </form>
    </>
  );
};

export const isAnnouncementCurrentlyActive = (
  announcement: AnnouncementModel,
) =>
  announcement.isActive &&
  (dayjs(announcement.from).isBefore(new Date()) || !announcement.from) &&
  (dayjs(announcement.to).isAfter(new Date()) || !announcement.to);

const useAnnouncementMessage = () => {
  const { t } = useTranslation(NAMESPACES);
  const dateFormat =
    usePersonContext().state.settings?.dateFormat || 'YYYY/MM/DD';

  const message = (announcement: AnnouncementModel) => {
    if (!announcement.isActive) return;

    if (isAnnouncementCurrentlyActive(announcement)) {
      return t('admin.announcement.currentlyActive');
    }

    if (dayjs(announcement.from).isAfter(new Date())) {
      return t('admin.announcement.willBeActiveAtDate', {
        date: dayjs(announcement.from).format(dateFormat),
      });
    }

    if (dayjs(announcement.to).isBefore(new Date())) {
      return t('admin.announcement.isNotActiveAnymore', {
        date: dayjs(announcement.to).format(dateFormat),
      });
    }
  };

  return { message };
};

const Container = styled('div', {
  display: 'flex',
  gap: '$small',
});
