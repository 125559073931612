import { Analytics, AnalyticsTrackEvent } from '@porsche-kado/analytics-client';
import { NotAuthorized, NotSignedIn, SignInForm } from '@porsche-kado/auth';
import { Spinner } from '@porsche-kado/ui';
import { Outlet } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PersonActionKind,
  Rule,
  useAbilityContext,
  usePersonContext,
} from '../context';
import { usePersonQuery } from '../graphql';

export const SignIn = (): JSX.Element => {
  const ability = useAbilityContext();
  const { dispatch: dispatchPerson, state: personState } = usePersonContext();
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  const isPasswordFlowActive =
    process.env.REACT_APP_IS_PASSWORD_FLOW_ACTIVE === 'true';

  const {
    data: person,
    isFetching,
    isSuccess,
  } = usePersonQuery(
    { ppnuid: personState.ppnuid || '' },
    {
      enabled: personState.isAuthenticated,
      refetchOnWindowFocus: false,
      retry: (_, error: Error) =>
        !error.message.includes('Trying to use unknown role'),
      select: (data) => data.person,
    },
  );

  useEffect(() => {
    if (isSuccess && !!person && !personState.isAuthorized) {
      dispatchPerson({
        type: PersonActionKind.Authorize,
        payload: person,
      });

      // Change language if it is not the default language.
      const locale = person.settings?.locale;
      if (locale && language !== locale) {
        changeLanguage(locale);
      }

      ability.update(person.ability.dashboard as Rule[]);

      Analytics.identify({
        id: person.id,
        organizationId: person.organization.parentMarket?.organizationId,
      });

      Analytics.track(AnalyticsTrackEvent.Login);
    }
  }, [
    isSuccess,
    person,
    dispatchPerson,
    language,
    ability,
    changeLanguage,
    personState.isAuthorized,
  ]);

  switch (true) {
    case isFetching:
    case personState.isInitializing:
    case isSuccess && !!person && !personState.isAuthorized:
      return <Spinner data-testid="loading-spinner" size="medium" />;
    case personState.isAuthenticated && !personState.isAuthorized:
      return <NotAuthorized />;
    case personState.isAuthenticated && personState.isAuthorized:
      return <Outlet />;
    case isPasswordFlowActive:
      return <SignInForm />;
    default:
      return <NotSignedIn />;
  }
};
