import { DataTable } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonInfoQuery } from '../../../graphql';

export const Settings = () => {
  const { t } = useTranslation(NAMESPACES);
  const { personId } = useParams({
    from: '/admin/person-info/$personId/settings',
  });

  const { data: person, isLoading } = usePersonInfoQuery(
    {
      id: +personId,
      includeDashboardAbility: false,
      includeFieldForceManagementAbility: false,
      includeGreenlandAbility: false,
      includeMultisensoryExperienceAbility: false,
      includeMysteryShoppingAbility: false,
      includeNetworkPlanningAbility: false,
      includePorscheStrategy2030Ability: false,
      includeRetailDesignAbility: false,
      includeSurveyAbility: false,
    },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const settings = Object.entries(person?.settings ?? {}).map(
    ([name, value]) => ({ name, value }),
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<(typeof settings)[0]>();

    return [
      columnHelper.accessor('name', {
        cell: (data) => data.getValue(),
        header: () => t('common:name'),
      }),
      columnHelper.accessor('value', {
        cell: (data) => {
          const value = data.getValue();
          if (data.row.original.name === 'is24HourFormat') {
            if (typeof value === 'boolean') {
              return value ? t('common:yes') : t('common:no');
            }

            return '-';
          }
          return value ?? '-';
        },
        header: () => t('common:value'),
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ];
  }, [t]);

  return (
    <DataTable
      isLoading={isLoading}
      caption="Settings"
      data={settings}
      columns={columns}
      idAccessor="name"
      pagination={{
        position: 'bottom',
      }}
      i18n={{
        filterLabel: (columnName) =>
          t('common:iconLabel.filter', { columnName }),
        optionAll: t('common:all'),
        buttonReset: t('common:action.reset'),
        buttonFilter: t('common:action.filter'),
        actionSearch: t('common:action.search'),
        noData: t('common:noData'),
      }}
    />
  );
};
