import {
  PButtonGroup,
  PHeading,
  PHeadingProps,
  PModal,
  type PModalProps,
} from '@porsche-design-system/components-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { globalCss, styled } from '../stitches.config';

globalCss({
  'body.scroll-lock': {
    overflow: 'hidden',
  },
})();

const StyledModal = styled(PModal, {
  '--p-modal-width': 'clamp(600px, 70vw, 800px)',

  variants: {
    size: {
      small: {
        '--p-modal-width': 'clamp(400px, 30vw, 500px)',
      },
      large: {
        '--p-modal-width': 'clamp(600px, 70vw, 1150px)',
      },
      max: {
        '--p-modal-width': 'clamp(600px, 70vw, 1500px)',
      },
    },
  },
});

export const Modal = ({
  children,
  open,
  ...props
}: {
  size?: 'small' | 'large' | 'max';
  children: React.ReactNode;
} & Omit<PModalProps, 'fullscreen'>) => {
  const [remainOpen, setRemainOpen] = useState(open);

  useEffect(() => {
    if (open) {
      // Necessary for conditional rendering, so that the content is not immediately hidden when the modal is closed.
      setRemainOpen(true);
    }

    const isAnyModalOpen = !!document.querySelectorAll(
      'p-modal[aria-hidden=false]',
    ).length;

    // The PDS removes the scroll lock after the modal has been closed.
    // However, as several modals may be open, the scroll lock can only be removed when no modal is open.
    isAnyModalOpen
      ? document.body.classList.add('scroll-lock')
      : document.body.classList.remove('scroll-lock');

    return () => {
      // Cleanup scroll lock if component completely removed from DOM
      const isAnyModalOpen = !!document.querySelectorAll(
        'p-modal[aria-hidden=false]',
      ).length;

      if (!isAnyModalOpen) {
        document.body.classList.remove('scroll-lock');
      }
    };
  }, [open]);

  const showContent = open || remainOpen;

  return createPortal(
    <StyledModal
      role="dialog"
      aria-hidden={!open}
      fullscreen={{ base: true, s: false }}
      open={open}
      {...props}
    >
      {showContent ? children : null}
    </StyledModal>,
    document.body,
  );
};

const Header = ({ children, ...props }: PHeadingProps) => (
  <PHeading slot="header" size="large" tag="h2" {...props}>
    {children}
  </PHeading>
);
Modal.Header = Header;

const Footer = ({
  sticky = false,
  children,
}: {
  sticky?: boolean;
  children: React.ReactNode;
}) => (
  <PButtonGroup slot={sticky ? 'footer' : undefined}>{children}</PButtonGroup>
);
Modal.Footer = Footer;
